import { environmentFromOrigin, setupConfig } from "@/config";
import { capture } from "@/services/telemetryService";
import { postTelemetries } from "@/services/unauthService/telemetry";
import StripHtml from "@/utils/StripHtml";

interface ExternalTelemObject {
  action: string;
  url: string;
  title: string;
  id: any;
  page: string;
  type: string;
  relevance: any;
  sortOrder: any;
  accessObject?: any;
  assignedContent?: boolean;
}

interface CtaCeCredit {
  url: string;
  title: string;
  unauth: boolean;
  objType: string;
  scenario: string | undefined;
  pageToGo: string | undefined;
}

/**
 * callback to collect internal telemetry
 * @param reqObj
 * @returns Promise
 */
function collectTelemetry(reqObj: any, unauth: boolean) {
  if (unauth) {
    return postTelemetries(reqObj);
  }
  return capture(reqObj);
}

/**
 * Telemetry capture object
 *  - since there is so much overlap between GA and our internal telemetry service place all calls here
 */
const telemetry = {
  // called on every vue router page change
  view_page: async (
    action: string,
    url: string,
    title: string,
    unauth: boolean
  ) => {
    if (url == "/" && !unauth) return;
    await setupConfig(environmentFromOrigin(), true);

    // capture gtag event
    if (window.gtag) {
      window.gtag("event", "page-view", url);
    }

    // also ourselves
    return collectTelemetry(
      {
        action: action,
        factor: [
          {
            type: "userInteraction",
            page: window.location.origin + url,
          },
        ],
        object: {
          type: "page",
          objectId: window.btoa(title),
          objectTitle: title,
        },
      },
      unauth
    );
  },

  add_to_list: async (
    action: string,
    url: string,
    type: string,
    title: string,
    id: any
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    const idToString = id ?? "";
    return collectTelemetry(
      {
        action: action,
        factor: [
          {
            type: "userInteraction",
            page: window.location.origin + url,
          },
        ],
        object: {
          type: type,
          objectId: idToString.toString(),
          objectTitle: title,
        },
      },
      false
    );
  },

  external: async (extObj: ExternalTelemObject, unauth: boolean) => {
    await setupConfig(environmentFromOrigin(), true);
    const idToString = extObj.id ?? "";
    return collectTelemetry(
      {
        action: extObj.action,
        factor: [
          {
            type: "userInteraction",
            page: window.location.origin + extObj.page,
            relevancyScore: extObj.relevance,
            carouselSortOrder: extObj.sortOrder,
            assignedContent: extObj.assignedContent,
          },
          ...(extObj.accessObject && Object.values(extObj.accessObject).length
            ? [extObj.accessObject]
            : []),
        ],
        object: {
          type: extObj.type,
          objectId: idToString.toString(),
          objectTitle: extObj.title,
          metadata: {
            url: extObj.url,
            provider: "apollo",
          },
        },
      },
      unauth
    );
  },

  carousel_change: async (
    action: string,
    title: string,
    id: any,
    url: string,
    unauth: boolean
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    const idToString = id ?? "";
    return collectTelemetry(
      {
        action: action,
        factor: [
          {
            type: "userInteraction",
            page: url,
          },
        ],
        object: {
          type: "course-carousel",
          objectId: idToString.toString(),
          objectTitle: title,
        },
      },
      unauth
    );
  },

  custom404: async (action: string, url: string, from: string) => {
    await setupConfig(environmentFromOrigin(), true);
    return collectTelemetry(
      {
        action: action,
        factor: [
          {
            type: "userInteraction",
            page: window.location.origin + url,
          },
        ],
        object: {
          type: "navPath",
          objectId: window.btoa(from),
          objectTitle: from,
        },
      },
      false
    );
  },

  belowTheFold: async (section: string, unauth: boolean) => {
    await setupConfig(environmentFromOrigin(), true);
    const page = window.document.title;
    return collectTelemetry(
      {
        action: "below-pagefold",
        factor: [
          {
            type: "userInteraction",
            page: page,
          },
        ],
        object: {
          type: "page",
          objectId: window.btoa(section),
          objectTitle: section,
        },
      },
      unauth
    );
  },

  browseContent: async (
    title: string,
    url: string,
    subNav: string,
    sort: string,
    unauth: boolean
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    const id = window.btoa(title);
    //subNavs Routes
    const subNavs = [
      "all",
      "PodcastDetails",
      "VideoSeries",
      "Path",
      "certifications",
      "certification",
      "explore-type",
    ];

    if (!subNavs.includes(subNav)) {
      return Promise.resolve();
    }

    //SubNav Telemetries
    const idToString = id ?? "";
    return collectTelemetry(
      {
        action: "browse-contentType",
        factor: [
          {
            type: "userInteraction",
            page: window.location.origin + url,
            subNav: subNav,
            sort: sort,
          },
        ],
        object: {
          type: "page",
          objectId: idToString.toString(),
          objectTitle: title,
        },
      },
      unauth
    );
  },

  learnerSearch: async (query: string) => {
    await setupConfig(environmentFromOrigin(), true);
    return collectTelemetry(
      {
        action: "search-requested",
        object: {
          type: "searchQuery",
          objectId: window.btoa(query),
          objectTitle: query,
        },
      },
      false
    );
  },
  addCartDetails: async (action: string, url: string, productObj: any) => {
    await setupConfig(environmentFromOrigin(), true);
    const paymentDetails = productObj.paymentType
      ? {
          type: "payment-method",
          paymentType: productObj.paymentType,
        }
      : "";

    return collectTelemetry(
      {
        action: action,
        factor: [
          paymentDetails,
          {
            line_items: [...productObj.productItems],
          },
          {
            type: "userInteraction",
            page: url,
          },
        ].filter((el) => el != ""),
        object: {
          type: "cart-details",
          objectId: productObj.basketId,
        },
      },
      false
    );
  },
  marqueeCardDetails: async (
    marqueeObj: {},
    objectTitle: string,
    url: string
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    const id = window.btoa(url);
    const strippedTitle = StripHtml(objectTitle);

    return collectTelemetry(
      {
        action: "marqueeCard-click",
        factor: [
          {
            type: "userInteraction",
            page: url,
          },
          marqueeObj,
        ],
        object: {
          type: "marquee-card",
          objectId: id,
          objectTitle: strippedTitle,
        },
      },
      false
    );
  },

  click_on_cta: async (
    url: string,
    title: string,
    unauth: boolean,
    objType: string
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    return collectTelemetry(
      {
        action: "click-on-cta",
        factor: [
          {
            type: "userInteraction",
            page: window.location.origin + url,
          },
        ],
        object: {
          type: objType,
          objectId: window.btoa(title),
          objectTitle: title,
        },
      },
      unauth
    );
  },

  click_on_cta_ceCredit: async (
    ceOpts: CtaCeCredit,
    input?: string | undefined,
    subNav?: string | undefined
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    let factor = [] as Array<any>;
    const object = {
      type: ceOpts.objType,
      objectId: window.btoa(ceOpts.title),
      objectTitle: ceOpts.title,
    } as any;

    if (ceOpts.scenario)
      factor.push({
        scenario: ceOpts.scenario,
        page: window.location.origin + ceOpts.url,
        type: "userInteraction",
      });

    if (ceOpts.pageToGo) object.metadata = { url: ceOpts.pageToGo };

    if (input)
      factor = [
        {
          input: input,
          page: window.location.origin + ceOpts.url,
          type: "userInteraction",
        },
      ];

    if (subNav && ceOpts.scenario)
      factor = [
        {
          type: "userInteraction",
          page: window.location.origin + ceOpts.url,
          subNav: subNav,
          scenario: ceOpts.scenario,
        },
      ];

    const payload = {
      action: "click-on-cta",
      factor: factor,
      object: object,
    } as any;

    return collectTelemetry(payload, ceOpts.unauth);
  },

  sessionCreation: async (
    page: string,
    title: string,
    subNav: string,
    objectId: string,
    unauth: boolean
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    const subNavs = [
      "all",
      "PodcastDetails",
      "VideoSeries",
      "Path",
      "certifications",
      "certification",
      "explore-type",
    ];

    if (!subNavs.includes(subNav)) {
      return Promise.resolve();
    }

    return collectTelemetry(
      {
        action: "session-creation",
        factor: [
          {
            type: "userInteraction",
            page: page,
            subNav: subNav,
          },
        ],
        object: {
          type: "page",
          objectId: objectId,
          objectTitle: title,
        },
      },
      unauth
    );
  },

  remove_from_myLearning: async (
    page: string,
    url: string,
    title: string,
    objType: string,
    objectId: string,
    unauth: boolean
  ) => {
    await setupConfig(environmentFromOrigin(), true);
    return collectTelemetry(
      {
        action: "remove-from-myLearning",
        factor: [
          {
            type: "userInteraction",
            page,
          },
        ],
        object: {
          type: objType,
          objectId,
          objectTitle: title,
          metadata: {
            url: window.location.origin + url,
          },
        },
      },
      unauth
    );
  },
};

export default telemetry;
