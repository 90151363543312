import axios from "axios";
import { useCookies } from "vue3-cookies";
const cookies = useCookies().cookies;

export function localStoreOrCookie(target: string) {
  // we have a version in localStorage, so use it
  if (
    localStorage[target as keyof typeof localStorage] &&
    localStorage[target as keyof typeof localStorage] != "null" &&
    localStorage[target as keyof typeof localStorage] != "undefined"
  ) {
    return localStorage[target as keyof typeof localStorage] as string;
  }
  // that failed, grab it from the cookie, set it in local storage, run away
  const cookieVal = cookies.get(target);
  if (cookieVal && cookieVal != "null") {
    localStorage[target as keyof typeof localStorage] = cookieVal;
    return cookieVal;
  }
  return false;
}

/**
 * Wrapper around our XHR/ajax calls
 */
export default function unauthenticatedHttp() {
  const instance = axios.create({
    headers: {
      Accept: "application/json",
    },
  });

  instance.defaults.headers.common["session-id"] =
    localStoreOrCookie("session-id");
  instance.defaults.headers.common["applicationId"] = window.env.APPLICATION_ID;

  instance.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return response;
    },
    function (error) {
      if (error?.response?.status == 403) {
        window.localStorage.clear();
      }
      return Promise.reject(new Error(error?.response?.data?.error ?? error));
    }
  );

  return instance;
}
